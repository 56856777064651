import React from "react"

import Layout from "../../components/layout"
import ThemeList from "../../components/ThemeList"

const ThemesPage = () => {
  return (
    <Layout>
      <ThemeList />
    </Layout>
  )
}

export default ThemesPage
